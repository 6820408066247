.l-form {
  border-radius: 12px;
  height: fit-content;
  padding: 30px;
  background: #191919;
  &__title {
    color: $gold;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
}
.sec-form {
  width: 440px;
}

@media (max-width: 500px) {
  .l-form {
    padding: 20px 10px 30px;
    & + & {
      margin-top: 30px;
    }
  }
  .sec-form {
    width: 100%;
  }
}
