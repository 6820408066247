.error {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__title {
    margin: 25px 0 45px;
  }
  &__text {
    margin-bottom: 30px;
  }
  .s-input {
    width: 350px;
    margin: 40px 0;
  }
}
