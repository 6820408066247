@import '../../styles/vars';

.btn {
  display: flex;
  align-items: center;
  height: auto;
  padding: 9px 10px;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background: $gold-bg;
  color: $dark-grey-1;
  font-family: MB;
  text-transform: uppercase;
  white-space: normal;

  &:hover,
  &:active,
  &:focus {
    background: $gold-bg;
    color: $dark-grey-1;
  }

  &.centered {
    margin: 0 auto;
  }
  &:disabled {
    background: #d6b328;
    color: black;
  }
  &__spinner {
    width: 20px;
    margin-right: 10px;
  }

  &-lg {
    padding: 20px;
  }

  &-sm {
    font-size: 10px;
    line-height: 12px;
    padding: 11.5px 10px;
  }

  &-black {
    background: $light-black-2;
    color: white;

    &:hover,
    &:active,
    &:focus {
      background: $light-black-2;
      color: white;
    }
  }
  svg {
    margin-right: 10px;
    path {
      fill: black;
    }
  }
  svg + span {
    margin-bottom: -4px;
  }
}
