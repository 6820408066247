@import '../../styles/vars';
.home {
  &__content {
    padding: 90px 0 100px;
  }
  &__slider {
    .swiper-pagination-bullet {
      width: 11px;
      transition: 0.2s;
      background: rgba(255, 255, 255, 0.3);
      height: 6px;
      opacity: 1;
      border-radius: 8px;
      &-active {
        width: 26px;
        background: white;
      }
    }
    &-slide {
      font-family: MR;
      padding: 170px 0 185px;
      position: relative;
      &-row {
        position: relative;
        z-index: 1;
      }
      &-bg {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
      &-text {
        color: white;
        margin: 20px 0 40px;
      }
    }
  }
  &__info {
    background: url(../../assets/img/home/info-mask.svg);
    background-size: 100%;
    &-row {
      display: flex;
    }
    &-box {
      max-width: 400px;
      & + & {
        margin-left: 19vw;
      }
    }
    &-title {
      text-transform: uppercase;
    }
    &-text {
      margin-top: 50px;
      font-family: MR;
      & + & {
        margin-top: 20px;
      }
    }
    &-coin {
      width: 192px;
      margin-top: 60px;
    }
    &-btn {
      margin-top: 39px;
    }
  }
  &__proof {
    padding-bottom: 90px;
    background: $gold-bg;
    color: $gray;
    &-title {
      font-family: MB;
      text-transform: uppercase;
    }
    &-subtitle {
      color: #86701c;
      max-width: 1040px;
      font-family: MB;
      margin-top: 14px;
      text-transform: uppercase;
    }
    &-info {
      max-width: 690px;
      margin: 100px 0 55px;
      &-title {
        margin-bottom: 20px;
        font-family: MB;
      }
    }
    &-features {
      &-cards {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
      }
    }
  }
  &__about {
    padding-bottom: 55px;
    background: $gold-bg;
    color: $gray;
    &-cards {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
    }
    &-btn {
      width: fit-content;
      display: block;
      margin-bottom: 50px;
    }
    &-text {
      margin: 20px 0 30px;
      p + p {
        margin-top: 15px;
      }
    }
  }
  &__products {
    padding: 105px 0 50px;
    &-box {
      margin-top: 75px;
    }
    &-disclaimer {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      font-family: MB;
      margin-top: 100px;
      p + p {
        margin-top: 15px;
      }
    }
  }
  &__bg {
    padding: 180px 0;
    background: url(../../assets/img/home/bg.jpg);
    background-size: cover;
    &-title {
      color: white;
    }
  }
  &__contact {
    padding: 105px 0 100px;
    &-row {
      display: flex;
      flex-wrap: wrap;
    }
    &-box {
      & + & {
        margin-left: 13vw;
      }
    }
    &-title {
      max-width: 400px;
      text-transform: uppercase;
    }
    &-text {
      margin-top: 45px;
      a {
        margin-left: 5px;
        color: white;
      }
    }
    &-form {
      &-title {
        margin-bottom: 35px;
      }
    }
  }
}
@media (max-width: 1450px) {
  .home {
    &__slider {
      &-slide {
        &-bg {
          height: 100%;
          left: 50%;
          top: 0;
          width: auto;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .home {
    &__contact {
      &-box {
        & + & {
          margin-left: 0;
          margin-top: 40px;
        }
      }
    }
    &__info {
      &-box {
        & + & {
          margin-left: 30px;
        }
      }
    }
  }
}

@media (max-width: 776px) {
  .home {
    &__slider {
      &-slide {
        padding: 230px 0 50px;
        &-bg {
          transform: translate3d(-50%, 0, 0);
        }
        &-row {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-title {
          margin: 0 auto;
        }
        &-text {
          margin: 20px 0;
        }
      }
    }
    &__content {
      padding: 45px 0 60px;
    }
    &__proof {
      padding: 30px 0;
      &-info {
        margin: 20px 0 60px;
      }
      &-features {
        &-cards {
          margin-top: 25px;
          display: flex;
          flex-direction: column;
        }
      }
    }
    &__about {
      padding: 30px 0;
      &-cards {
        display: flex;
        margin-top: 30px;
        flex-direction: column;
      }
    }
    &__info {
      &-coin {
        display: none;
      }
      &-row {
        flex-direction: column;
      }
      &-box {
        & + & {
          margin-left: 0;
          margin-top: 60px;
        }
      }
    }
    &__products {
      padding: 30px 0;
      &-row {
        padding: 0 !important;
      }
      &-title {
        padding: 0 20px;
      }
      &-box {
        margin-top: 20px;
      }
      &-disclaimer {
        margin-top: 60px;
      }
    }
    &__bg {
      padding: 165px 0 90px;
      background-image: url(../../assets/img/home/bg-mobile.jpg);
      text-align: center;
    }
    &__contact {
      padding: 30px 0 50px;
      &-text {
        margin-top: 20px;
      }
    }
  }
}
