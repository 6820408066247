.text {
  font-size: 18px;
  line-height: 22px;

  &-sm {
    font-size: 12px;
    line-height: 15px;
  }

  &-md {
    font-size: 15px;
    line-height: 18px;
  }

  &-lg {
    font-size: 20px;
    line-height: 24px;
  }

  &-gradient {
    width: fit-content;
    background: $gold-bg;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-bold {
    font-family: MB;
  }
  &-uppercase {
    text-transform: uppercase;
  }
}

.h1 {
  &-lg {
    font-size: 40px;
    line-height: 49px;
  }
  &-xl {
    font-size: 60px;
    line-height: 73px;
    font-family: MB;
  }
  &-md {
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    font-family: MB;
  }
}

.h2 {
  font-size: 26px;
  line-height: 32px;
  &-md {
    font-size: 26px;
    line-height: 32px;
  }
}

.h3 {
  font-size: 22px;
  line-height: 27px;
}

.products-title {
  text-transform: uppercase;
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .h1 {
    &-lg {
      font-size: 26px;
      line-height: 32px;
    }
    &-xl {
      font-size: 40px;
      line-height: 49px;
    }
    &-md {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .products-title {
    margin-bottom: 30px;
  }
}
