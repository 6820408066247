@import '../../styles/vars';

.footer {
  padding: 80px 0 26px;
  background: $dark-grey-2;

  &__content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: 0 auto;

    &-links {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 51px;

      &-block {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        & + & {
          margin-left: 30%;
        }

        &-title {
          margin-bottom: 13px;
        }

        &-link {
          color: white;

          & + & {
            margin-top: 20px;
          }
        }
      }
    }

    &-copyright {
      font-family: MM;
      text-transform: uppercase;
      text-align: center;
    }
  }
}

@media (max-width: 1350px) {
  .footer {
    padding: 50px 0 30px;

    &__content {
      grid-template-columns: 1fr;
      width: 80%;

      &-links {
        justify-content: flex-start;
        margin: 60px 0;
      }
    }
  }
}

@media (max-width: 700px) {
  .footer {
    padding: 50px 0 30px;

    &__content {
      width: 100%;

      &-links {
        flex-direction: column;

        &-block {
          & + & {
            margin-left: 0;
          }
          &:nth-child(1) {
            margin-bottom: 60px;
          }
        }
      }
    }
  }
}
