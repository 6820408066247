@import '../../styles/vars';
.product {
  padding: 80px 0 50px;
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__img {
    width: 550px;
    img {
      width: 100%;
    }
  }
  &__content {
    max-width: 500px;
  }
  &__name {
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    font-family: MB;
    margin-bottom: 35px;
  }
  &__wrapper {
    margin: 22px 0 50px;
    display: flex;
    align-items: center;
  }
  &__count {
    font-size: 14px;
    line-height: 17px;
    font-family: MB;
    margin: 15px 0 50px;
  }
  .ant-progress {
    &-line {
      width: 392px;
    }
    &-inner {
      background: #41403e;
      border-radius: 12px;
    }
    &-bg {
      height: 16px !important;
      background: linear-gradient(
          46.19deg,
          #fac929 10.42%,
          #fef8c2 55.03%,
          #fac929 96.91%
        ),
        #d6b955;
    }
  }
  &__add {
    margin-left: 20px;
  }
  &__info {
    margin-bottom: 30px;
    margin-top: 50px;
    &-item {
      display: flex;
      align-items: center;
      & + & {
        margin-top: 10px;
      }
      &-value {
        font-family: MB;
      }
      &-title {
        min-width: 265px;
        margin-right: 20px;
      }
    }
    &-descr {
      color: rgba(255, 255, 255, 0.4);
      margin-top: 30px;
    }
  }
  &__instok {
    font-size: 14px;
    line-height: 17px;
    color: #b99b55;
    text-transform: uppercase;
    font-family: MB;
  }
  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    &-item {
      text-transform: uppercase;
      transition: 0.3s;
      padding-bottom: 8px;
      position: relative;
      cursor: pointer;
      & + & {
        margin-left: 55px;
      }
      &::after {
        content: '';
        opacity: 0;
        transition: 0.3s;
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: $gold;
      }
      &.active {
        color: $gold;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  &__descr {
    text-align: center;
    padding: 50px 0 0;
    &-text {
      margin-bottom: 20px;
    }
  }
  &__reviews {
    &-make {
      width: fit-content;
      margin: 50px auto 0;
    }
    &-wrapper {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
}
@media (max-width: 767px) {
  .product {
    padding: 40px 0 60px;
    &__name {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    &__reviews {
      &-make {
        width: 100%;
      }
    }
    &__img {
      display: none;
      &-mobile {
        img {
          width: 100%;
        }
      }
    }
    &__content {
      max-width: 100%;
    }
    .ant-progress {
      &-bg {
        height: 8px !important;
      }
      &-line {
        width: 100%;
      }
    }
    &__count {
      margin: 5px 0 20px;
    }
    &__cost {
      margin: 0 auto;
    }
    &__wrapper {
      flex-direction: column;
    }
    &__add {
      margin: 25px 0 0;
      width: 100%;
      padding: 18px 0 !important;
      span {
        font-size: 16px;
        line-height: 20px;
        margin-left: 20px;
      }
    }
    &__nav {
      margin-top: 60px;
    }
  }
}
