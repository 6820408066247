.input {
  background-color: #363636 !important;
  border-radius: 12px !important;
  border: none;
  padding: 20px;
  font-family: MB;
  font-size: 14px;
  line-height: 17px;
  resize: none !important;
  box-shadow: none;
  color: white !important;
  textarea {
    border: none;
    font-family: MB;
    font-size: 14px;
    line-height: 17px;
    resize: none !important;
    box-shadow: none !important;
    color: white !important;
    &:hover,
    &:focus {
      background-color: #363636 !important;
      box-shadow: none;
      border-color: none;
    }
  }
  &__label {
    font-size: 14px;
    line-height: 17px;
    font-family: MB;
  }
  &__star {
    position: relative;
    &::before {
      content: '*';
      position: absolute;
      font-family: MB;
      font-size: 14px;
      line-height: 17px;
      color: #fce075;
      right: 0;
      bottom: calc(100% + 4px);
    }
  }
  &__forget {
    position: absolute;
    bottom: calc(100% + 6px);
    right: 15px;
    color: white;
    cursor: pointer;
  }
  .ant-input {
    background: #363636;
    color: white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #363636 inset !important;
  -webkit-text-fill-color: white !important;
}
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px;
}

.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number {
  border: none !important;
  border-color: none;
  outline: none !important;
  box-shadow: none !important;
  &:hover,
  &:focus {
    box-shadow: none;
    border-color: none;
  }
  &-input {
    padding: 0;
    text-align: center;
    width: 100%;
  }
}
.ant-checkbox {
  border-radius: 50%;
  overflow: hidden;
  &-inner {
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-checked &-inner {
    background: $gold-bg;
    border: none;
    &::after {
      border: none;
      min-width: 10px;
      min-height: 10px;
      background: $dark;
      border-radius: 50%;
      transform: scale(1);
    }
  }
  &-inner {
    width: 17px;
    height: 17px;
    &::after {
      position: static !important;
    }
  }
  &-checked {
    &::after {
      border: none;
    }
  }
}
.ant-form-item .ant-input-textarea-show-count::after {
  right: 0;
  color: white;
}
