@font-face {
  font-family: 'MB';
  src: url('../assets/fonts/Montserrat-Bold.woff') format('woff');
}
@font-face {
  font-family: 'ML';
  src: url('../assets/fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'MR';
  src: url('../assets/fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MM';
  src: url('../assets/fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'KB';
  src: url('../assets/fonts/Khula-Bold.woff') format('woff');
}
