@import '../../styles/vars';
.payments {
  &__choose {
    padding: 20px;
    &-box {
      display: flex;
      overflow: hidden;
      border-radius: 12px;
    }
    &-item {
      display: flex;
      align-items: center;
      background: #363636;
      font-size: 13px;
      line-height: 16px;
      cursor: pointer;
      padding: 20px;
      & + & {
        border-left: 1px solid $dark;
      }
      &.active {
        background: $gold-bg;
        color: black;
        svg {
          path {
            fill: black;
          }
        }
      }
      &-img {
        margin-right: 10px;
        min-width: 30px;
        min-height: 30px;
      }
    }
  }
  &__continue {
    width: 220px;
    margin-top: 30px !important;
  }
  &__send {
    padding: 30px 20px;
    margin-top: 30px;
  }
  &__send {
    &-text {
      font-size: 14px;
      line-height: 17px;
    }
    &-copy {
      background: #363636;
      border-radius: 12px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 20px;
      &-address {
        font-size: 14px;
        line-height: 17px;
      }
      img {
        cursor: pointer;
      }
    }
    &-qr {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      &-title {
        font-size: 14px;
        line-height: 17px;
      }
      &-box {
        padding: 20px;
        background: #fff;
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .payments {
    &__choose {
      &-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
@media (max-width: 767px) {
  .payments {
    &__choose {
      &-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1px;
      }
      &-item {
        & + & {
          border: none;
        }
      }
    }
    &__send {
      &-copy {
        &-address {
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 15px;
        }
      }
    }
  }
}
