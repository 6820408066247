.c-form {
  width: 500px;
  &__saved {
    margin-left: 10px;
  }
}

@media (max-width: 776px) {
  .c-form {
    width: 100%;
  }
}
