@import './reset';
@import './fonts';
@import './vars';
@import './typography';
@import '~antd/dist/antd.css';
@import './components/index.scss';

* {
  box-sizing: border-box;
}
body {
  font-family: MR;
  color: white;
  background: #000000;
  padding-top: 131px;
}
#root,
body,
html,
.proof {
  height: 100%;
}
main {
  flex-grow: 2;
}
.proof {
  display: flex;
  flex-direction: column;
}
.row {
  max-width: 1180px;
  padding: 0 20px;
  margin: 0 auto;
}

a {
  &:hover {
    color: $gold;
  }
  &.active {
    color: $gold;
    path {
      fill: $gold;
    }
  }
}

.desk-hidden {
  display: none;
}

.hidden {
  display: none;
}

@media (max-width: 776px) {
  body {
    padding-top: 51px;
  }
  .mobile-hidden {
    display: none;
  }
  .mobile-visible {
    display: block;
  }
  .mobile-visible-flex {
    display: flex;
  }
}
