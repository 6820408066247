.login {
  padding: 80px 0 65px;
  &__title {
    margin-bottom: 45px;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10vw;
  }
  &__info {
    margin-top: 100px;
    max-width: 985px;
    p + p {
      margin-top: 15px;
    }
  }
  &__link {
    font-family: MB;
  }
}

@media (max-width: 1024px) {
  .login {
    &__content {
      grid-column-gap: 5vw;
    }
  }
}

@media (max-width: 500px) {
  .login {
    padding: 40px 0 70px;
    &__title {
      margin-bottom: 20px;
    }
    &__content {
      display: block;
    }
  }
}
