@import '../../styles/vars';

.header {
  position: fixed;
  top: 0;
  width: 100vw;
  right: 0;
  z-index: 1000;
  text-transform: uppercase;
  left: 0;
  padding: 25px 0;
  background: #000;
  &__row {
    position: relative;
  }
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__menu {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    padding: 100px 0 60px;
    transition: 0.3s;
    background: #000;
  }
  &__popap {
    position: absolute;
    right: 20px;
    top: calc(100% + 25px);
    padding: 30px;
    background: black;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      display: block;
      background: $gold-bg;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__box {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: fit-content;
    & + & {
      margin-top: 30px;
      margin-left: 0;
    }
  }
  &__link {
    color: white;
    font-size: 12px;
    line-height: 15px;
    font-family: MM;
    & + & {
      margin-left: 20px;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    margin-right: 30px;
    &-item {
      color: #ffffff;
      font-family: MB;
      & + & {
        margin-left: 30px;
      }
    }
    &-open {
      display: none;
      width: 28px;
      height: 21px;
      padding: 3px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__icon {
    path {
      transition: 0.3s;
    }
    &-counter {
      border-radius: 50%;
      padding: 4px 5px;
      font-size: 11px;
      line-height: 10px;
      font-family: MB;
      color: #2e2e2e;
      position: absolute;
      background: #fbd54e;
      border: 2px solid #000000;
      bottom: calc(100% - 10px);
      left: calc(100% - 10px);
    }
    &-search {
      cursor: pointer;
      path,
      circle {
        transition: 0.3s;
      }
      &:hover {
        path,
        circle {
          stroke: $gold;
        }
      }
    }
    &-link {
      position: relative;
      margin: 0 24px;
      &:hover {
        svg {
          path {
            fill: $gold;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .header {
    &__nav {
      margin-right: 0;
    }
    &__box {
      flex-direction: column;
      align-items: flex-end;
    }
    &__wrapper {
      margin-top: 20px;
    }
  }
}

@media (max-width: 776px) {
  .header {
    padding: 12px 0;
    &__box {
      display: none;
      & + & {
        display: flex;
        margin-top: 0;
      }
    }
    &__nav {
      display: none;
      &-open {
        display: block;
      }
    }
    &__wrapper {
      margin-top: 0;
    }
    &__icon {
      &-link {
        margin: 0;
      }
    }
    &__menu {
      &.open {
        opacity: 1;
        visibility: visible;
        display: block;
        z-index: 999;
      }
      &-title {
        padding: 0 30px;
      }
      &-nav {
        margin: 15px 0 70px;
        &-item {
          padding: 8px 30px;
          display: block;
          position: relative;
          color: white;
          &::before {
            content: '';
            display: block;
            background: transparent;
            position: absolute;
            height: 100%;
            width: 4px;
            left: 0;
            top: 0;
          }
          &.active {
            background: #1d1d1d;
            &::before {
              background: $gold-bg;
            }
          }
        }
      }
    }
  }
}
