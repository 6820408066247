@import '../../styles/vars';

.box {
  &-border-gradient {
    position: relative;
    border: solid 5px transparent;
    background-clip: padding-box;
    box-shadow: 0 4px 25px rgba(157, 132, 36, 0.3),
      inset 0 4px 25px rgba(157, 132, 36, 0.3);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -5px;
      background: $gold-bg;
    }
  }

  &-products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 90px 45px;
  }
  &-fullpage {
    height: calc(100% - 341px);
  }
  &-dark {
    border-radius: 12px;
    background: $dark;
  }
  &-flex-row {
    display: flex;
    align-items: center;
  }
}
.products {
  padding: 60px 0 175px;
}

@media (max-width: 991px) {
  .box {
    &-products {
      grid-row-gap: 45px;
    }
  }
}

@media (max-width: 776px) {
  .products {
    padding: 40px 0 60px;
  }
  .box {
    &-products {
      display: flex;
      flex-direction: column;
    }
    &-products-mobile-scroll {
      display: flex;
      padding: 0 20px;
      overflow: scroll;
      grid-gap: 20px;
      .p-card {
        &:last-child {
          padding-right: 20px;
          min-width: 305px;
        }
      }
    }
    &-fullpage {
      height: auto;
    }
  }
}
