.security {
  padding: 100px 0;
  &__content {
    padding: 40px 40px 105px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text {
    text-align: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 1024px) {
  .security {
    padding: 50px 0;
    &__content {
      padding: 20px;
    }
  }
}
