@import '../../styles/vars';
.account {
  padding: 80px 0 125px;
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__wrapper {
    max-width: 130px;
  }
  &__name {
    font-family: MM;
    color: white;
    margin: 30px 0;
  }
  &__nav {
    &-item {
      display: block;
      margin-bottom: 30px;
      color: white;
      &.active {
        font-family: MB;
      }
    }
  }
  &__logout {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: $gold;
    }
  }
  &__content {
    width: 100%;
    max-width: 800px;
  }
}

@media (max-width: 1024px) {
  .account {
    &__content {
      max-width: 700px;
    }
  }
}

@media (max-width: 776px) {
  .account {
    padding: 20px 0 70px;
    &__row {
      flex-direction: column;
    }
    &__content {
      max-width: 100%;
    }
    &__box {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
    }
    &__wrapper {
      width: calc(100% + 40px);
      max-width: unset;
      background: $dark;
      padding: 20px 20px 30px;
      margin: 10px -20px 30px;
    }
    &__name {
      margin: 0;
      margin-left: 25px;
    }
    &__img {
      width: 47px;
    }
  }
}
