@import '../../styles/vars';
.ch-password {
  padding: 80px 0;
  &__title {
    margin-bottom: 45px;
  }
  &__box {
    background: $dark;
    border-radius: 12px;
    padding: 50px 0;
    display: flex;
    justify-content: center;
  }
}
