@import '../../styles/vars';

.franchise {
  &__presentation {
    padding: 120px 0 100px;
    background: $light-black-1;

    &_second,
    &_third {
      padding-top: 100px;
    }

    &-container {
      display: grid;
      grid-template-columns: auto 531px;
      grid-column-gap: 30px;
    }

    &_first &-container {
      grid-template-rows: 128px auto;
    }

    &_second &-container {
      grid-template-rows: 79px repeat(3, auto);
    }

    &-title {
      margin-bottom: 30px;
      font-family: MB;
      text-transform: uppercase;

      &_h2 {
        margin-top: 100px;
        margin-bottom: 33px;
        text-transform: initial;
      }
    }

    &_third &-title {
      margin-bottom: 40px;
    }

    &-text {
      grid-column: 1;
      max-width: 465px;

      &-bottom {
        text-transform: uppercase;
      }
    }

    &-images {
      grid-column: 2;
      grid-row: 1 / span 2;

      img {
        width: 100%;
      }
    }

    &_second &-images {
      grid-row: 2 / span 2;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      &_first {
        grid-column: span 2;
      }
    }

    &-items {
      grid-column: span 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-column-gap: 27px;
      grid-row-gap: 72px;
      z-index: 1;

      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 330px;
        height: 210px;
        padding: 0 35px;
        background: $light-black-1;

        &-icon {
          &-container {
            display: flex;
            align-items: center;
            justify-content: center;

            min-width: 64px;
            height: 64px;
            margin-bottom: 26px;
            border-radius: 50%;
            background: $gold-bg;
          }
        }

        &-text {
          text-align: center;
        }
      }
    }

    &_third &-items {
      justify-content: space-around;
      margin-bottom: 70px;
    }

    &-button {
      display: block;
      grid-column: span 2;
      margin: 0 auto;
    }
  }

  &__background-image {
    width: 100%;
    max-height: 650px;
    object-fit: cover;
  }

  &__gold-presentation {
    padding: 100px 0 70px;
    background: $gold-bg;
    color: $gray;

    &-link {
      width: fit-content;
      display: block;
      margin: 0 auto;
    }

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-title {
      margin-bottom: 49px;
      text-transform: uppercase;
    }

    &-text {
      max-width: 740px;
      margin-bottom: 60px;
    }

    &-items {
      display: grid;
      grid-template-columns: repeat(2, 500px);
      justify-content: space-around;
      margin-bottom: 70px;
    }

    &-button {
      grid-column: span 2;
      margin: 0 auto;
    }
  }
}

@media (max-width: 1099px) {
  .franchise {
    &__presentation {
      &-container {
        grid-template-columns: 1fr;
        grid-template-rows: auto !important;
        grid-gap: 0;
      }

      &-title {
        grid-column: 1;

        &_h2 {
          margin-top: 70px;
        }
      }

      &-text {
        max-width: 800px;

        &-bottom {
          grid-column: 1;
          margin-top: 30px;
        }
      }

      &-images {
        grid-row: 3 !important;
        grid-column: 1;
        margin: 40px auto 0 0;
      }

      &-items {
        justify-content: center;
        grid-gap: 40px;
      }
    }

    &__gold-presentation {
      &-items {
        grid-template-columns: minmax(300px, 500px);
        grid-gap: 30px;
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 760px) {
  .franchise {
    &__presentation {
      background: black;

      &_first {
        padding: 30px 0 60px;
      }

      &_second {
        padding: 0 0 40px;
      }

      &_third {
        padding: 40px 0 70px;
      }

      &-title {
        font-family: MM;
        margin-bottom: 20px;

        &_h2 {
          margin-top: 50px;
          font-family: MB;
        }
      }

      &_third &-title {
        margin-bottom: 30px;
      }

      &-images {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
      }

      &-items {
        grid-gap: 20px;
      }

      &_second &-items {
        &-item {
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          height: auto;
          padding: 0;
          border: none !important;
          background: black;
          box-shadow: none !important;

          &:before {
            display: none;
          }

          &-icon {
            &-container {
              margin-right: 20px;
              margin-bottom: 0;
            }
          }

          &-text {
            text-align: left;
          }
        }
      }

      &_third &-items {
        margin-bottom: 30px;

        &-item {
          background-color: black;
        }
      }

      &_third &-button {
        max-width: 330px;
      }
    }

    &__background-image {
      display: none;
    }

    &__gold-presentation {
      padding: 30px 0 40px;

      &-title {
        margin-bottom: 10px;
      }

      &-text {
        margin-bottom: 30px;
      }
    }
  }
}
