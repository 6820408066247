.c-item {
  background: #191919;
  padding: 10px 20px 10px 35px;
  border-radius: 12px;

  display: flex;
  & + & {
    margin-top: 10px;
    .c-item__elem span {
      display: none;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 60px 110px;
    grid-column-gap: 20px;
  }
  &__box {
    display: grid;
    grid-template-columns: 400px 115px 150px 1fr;
    grid-column-gap: 20px;
    margin-left: 20px;
  }
  &__close {
    cursor: pointer;
    &-box {
      display: flex;
      z-index: 10;
      align-items: center;
    }
  }
  &__img {
    width: 100%;
  }
  &__elem {
    position: relative;
    padding-top: 30px;
    display: flex;
    align-items: center;
    height: 60px;
    span {
      position: absolute;
      top: -35px;
      color: #fff;
    }
  }
  &__name {
    font-size: 16px;
    line-height: 20px;
  }
  &__cost {
    font-family: MB;
  }
  .counter {
    padding: 0;
    background: transparent;
    border: none;
  }
}
@media (max-width: 1200px) {
  .c-item {
    display: flex;
    position: relative;
    &__wrapper {
      display: block;
      max-width: 30%;
    }
    &__close {
      &-box {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .c-item {
    padding: 20px;
    & + & {
      margin-top: 30px;
      .c-item__elem span {
        display: block;
      }
    }
    &__box {
      display: flex;
      flex-direction: column;
    }
    &__elem {
      height: auto;
      padding-top: 0;
      flex-direction: column;
      align-items: flex-start;
      & + & {
        margin-top: 50px;
      }
      span {
        position: static;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .c-item {
    &__elem {
      span {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
