.d-form {
  width: 100%;
  max-width: 500px;
  &__info {
    color: white;
    margin-bottom: 40px;
  }
  &__btn {
    margin-top: 15px;
  }
  &__saved {
    margin-left: 10px;
  }
}
