@import '../../styles/vars';
.addresses {
  &__title {
    &-form {
      margin-bottom: 70px;
    }
  }
  &__box {
    display: grid;
    grid-template-columns: 350px 350px;
    grid-column-gap: 50px;
    margin-top: 50px;
  }
  &__item {
    padding: 35px 30px 30px;
    &-title {
      color: $gold;
      margin-bottom: 30px;
    }
    &-text {
      margin-bottom: 20px;
    }
  }
  .a-form {
    max-width: 500px;
    &__saved {
      margin-left: 10px;
    }
  }
}

@media (max-width: 1025px) {
  .addresses {
    &__box {
      display: flex;
      flex-direction: column;
    }
    &__item {
      & + & {
        margin-top: 30px;
      }
    }
  }
}
@media (max-width: 500px) {
  .addresses {
    &__box {
      display: block;
    }
    &__item {
      & + & {
        margin-top: 30px;
      }
    }
  }
}
