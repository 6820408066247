.delivery {
  padding: 80px 0 100px;
  &__content {
    max-width: 985px;
  }
  &__shipping {
    margin: 45px 0 60px;
  }
  &__item {
    & + & {
      margin-top: 10px;
    }
    &-number {
      font-family: MB;
      margin-right: 5px;
    }
  }
  &__info {
    margin-top: 30px;
  }
  &__collection {
    margin-top: 30px;
    &-title {
      width: max-content !important;
    }
  }
}

@media (max-width: 776px) {
  .delivery {
    padding: 40px 0 70px;
  }
}
