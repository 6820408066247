@import '../../styles/vars';
.i-block {
  padding: 110px 0;
  &__content {
    padding: 50px;
    display: flex;
    background: $dark;
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
  }
  &__text {
    text-align: center;
    font-size: 30px;
    line-height: 37px;
    margin-top: 40px;
  }
  &__btn {
    margin-top: 20px;
    display: block;
  }
}
