@import '../../styles/vars';
.legal {
  padding: 80px 0;
  &__title {
    text-transform: uppercase;
  }
  &__tabs {
    margin: 50px 0 80px;
  }
  &__info {
    p + p {
      margin-top: 20px;
    }
  }
  a {
    word-wrap: break-word;
    color: $gold;
  }
  &__rights {
    margin-top: 60px;
    &-title {
      text-transform: uppercase;
      margin-bottom: 20px;
      width: max-content !important;
    }
    &-text {
      max-width: 984px;
      p + p {
        margin-top: 20px;
      }
    }
    &-list {
      margin-top: 20px;
      li + li {
        margin-top: 20px;
      }
      li {
        display: flex;
      }
      span {
        margin-right: 5px;
      }
      & + p {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .legal {
    &__row {
      &-tabs {
        padding: 0 !important;
      }
    }
    &__tabs {
      padding: 0 20px 10px;
      margin: 20px 0 30px;
      &-item {
        min-width: fit-content;
        &:last-child {
          padding-right: 20px;
          &::after {
            width: calc(100% - 20px);
          }
        }
        span {
          width: max-content;
        }
      }
    }
  }
}
