$gold: #d6b328;
$gold-bg: linear-gradient(
    46.19deg,
    #fac929 10.42%,
    #fef8c2 55.03%,
    #fac929 96.91%
  ),
  #d6b955;
$gray: #413b24;
$light-black-1: #1b1b1b;
$light-black-2: #171717;
$dark-grey-1: #2e2e2e;
$dark-grey-2: #202020;
$dark: #191919;
