.p-form {
  width: 500px;
  &__rate {
    display: flex;
    align-items: center;
    &-text {
      font-size: 14px;
      line-height: 17px;
      font-family: MB;
      color: white;
      margin-right: 12px;
    }
  }
}

@media (max-width: 767px) {
  .p-form {
    width: 100%;
  }
}
