.tabs {
  display: flex;
  align-items: center;
  &__item {
    text-transform: uppercase;
    transition: 0.3s;
    padding-bottom: 8px;
    position: relative;
    cursor: pointer;
    & + & {
      margin-left: 55px;
    }
    &::after {
      content: '';
      opacity: 0;
      transition: 0.3s;
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: $gold;
    }
    &.active {
      color: $gold;
      &::after {
        opacity: 1;
      }
    }
  }
}
@media (max-width: 1024px) {
  .tabs {
    overflow: scroll;
    &__item {
      & + & {
        margin-left: 25px;
      }
    }
  }
}
