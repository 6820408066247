.cart {
  padding: 80px 0;
  &__title {
    margin-bottom: 55px;
  }
  &__info {
    margin-top: 20px;
  }
  &__total {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 12px;
      line-height: 15px;
    }
    &-cost {
      margin: 7px 0 30px;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 37px;
      text-transform: uppercase;
      font-family: MB;
    }
  }
}

@media (max-width: 767px) {
  .cart {
    padding: 40px 0 70px;
    .counter {
      padding: 6px 7px;
      background: #272727;
      border: 1px solid #2d2d2d;
    }
    &__title {
      margin-bottom: 5px;
    }
  }
}
