@import '../../styles/vars';
.p-review {
  background: #191919;
  border-radius: 12px;
  padding: 15px 25px 25px 15px;
  display: flex;
  justify-content: space-between;
  & + & {
    margin-top: 15px;
  }
  &__img {
    width: 47px;
  }
  &__content {
    margin-left: 20px;
    width: 100%;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__name {
    text-transform: uppercase;
    font-family: MB;
    color: $gold;
  }
  &__text {
    margin-top: 10px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .p-review {
    padding: 10px;
    &__content {
      margin-left: 10px;
    }
    &__name {
      font-size: 13px !important;
      line-height: 15px !important;
    }
    .ant-rate-star {
      width: 15px !important;
      height: 15px !important;
    }
    .anticon-star {
      width: 100% !important;
      svg {
        width: 100% !important;
      }
    }
  }
}
