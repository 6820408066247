@import '../../styles/vars';
.orders {
  &__item {
    border-radius: 12px;
    background: #191919;
    padding: 30px;
  }
  &__item {
    & + & {
      margin-top: 40px;
    }
    &-info {
      &-date {
        color: $gold;
      }
      &-cost {
        font-family: MB;
      }
      &-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & + & {
          margin-top: 10px;
        }
      }
    }
    &-product {
      margin-top: 30px;
      display: flex;
      font-family: MB;
      &-element {
        padding-top: 30px;
      }
      &-info {
        display: grid;
        grid-template-columns: 3fr 40px 1fr;
        grid-column-gap: 20px;
        margin-left: 20px;
        width: 100%;
      }
      &-img {
        width: 110px;
      }
      &-name {
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        font-family: MR;
      }
      &-cost {
        width: 100%;
        text-align: right;
      }
    }
  }
}

@media (max-width: 776px) {
  .orders {
    &__item {
      padding: 10px 10px 20px;
      &-product {
        &-element {
          padding-top: 0;
          & + & {
            margin-top: 30px;
          }
          span:first-child {
            display: block;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 5px;
          }
        }
        &-cost {
          text-align: left;
        }
        &-info {
          display: block;
          margin-left: 10px;
        }
        &-img {
          width: 60px;
          height: fit-content;
        }
      }
    }
  }
}
