@import '../../styles/vars';
.p-card {
  width: 100%;
  max-width: 350px;
  text-align: center;
  font-family: MB;
  &__coin &__box {
    padding-top: 0;
  }
  &__img {
    display: block;
    img {
      width: 100%;
    }
  }
  &__box {
    padding: 35px 20px 20px;
  }
  &__name {
    font-size: 14px;
    line-height: 17px;
  }
  &__cost {
    margin: 20px 0 12px;
    color: $gold;
  }
}

@media (max-width: 776px) {
  .p-card {
    min-width: 285px;
  }
}
