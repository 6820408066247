@import '../../styles/vars';
.r-password {
  padding: 80px 0;
  &__title {
    margin-bottom: 45px;
  }
  &__box {
    background: $dark;
    border-radius: 12px;
    padding: 70px 0 40px;
    display: flex;
    justify-content: center;
  }
  .i-block {
    padding-top: 0;
  }
}
