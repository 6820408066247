.ant-notification {
  &-notice {
    background: #363636;
    border-radius: 12px;
    &-message {
      color: white;
    }
    &-close {
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: $gold;
        }
      }
    }
  }
}
